import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { Component,  HostListener,  Input,  OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  Subject,
  takeUntil,
  forkJoin,
  take,
  mergeMap,
  map,
  of,
} from 'rxjs';
import { GiftItem } from 'src/app/Models/gift-item.model';
import { ProductDetailQuickViewService } from 'src/app/Services/product-detail-quick-view-service/product-detail-quick-view.service';
import { ShoppingCartService } from '../../Services/shopping-cart.service';
import {
  ItemMessage,
  CartItem,
  ShoppingCart,
  UnitsOfMeasure,
} from '../../Models/shopping-cart';
import { GiftPackage } from 'src/app/Models/gift-package.model';
import {
  MatSnackBar,
  MatSnackBarConfig,
  MatSnackBarHorizontalPosition,
  MatSnackBarVerticalPosition
} from "@angular/material/snack-bar";
import { HttpErrorResponse } from "@angular/common/http";
import { environment } from 'src/environments/environment';
import { CloudFile } from 'src/app/Models/cloud-file';
import { AddToCartAmountUpdateMessageComponent } from '../add-to-cart-amount-update-message/add-to-cart-amount-update-message.component';
import { CarouselComponent, OwlOptions, SlidesOutputData } from 'ngx-owl-carousel-o';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import { Location } from '@angular/common';
import { RouterEventService } from '../RouterEvent.service';
import {SpinnerService} from "@xyg/spinner";
import { Title, Meta } from '@angular/platform-browser';

@Component({
  selector: 'xgy-product-detail-quick-view',
  templateUrl: './product-detail-quick-view.component.html',
  styleUrls: ['./product-detail-quick-view.component.scss'],
  animations: [
    // Trigger is used here
    trigger('animate', [
      state(
        'green',
        style({
          'background-color': '#00D250',
          transform: 'translateX(0)',
        })
      ),
      state(
        'red',
        style({
          'background-color': '#f46f61',
          color: '#ffffff',
          transform: 'translateX(0)',
        })
      ),
      state(
        'white',
        style({
          'background-color': '#ffffff',
          transform: 'translateX(0)',
        })
      ),
      transition('green => red', animate(1200)),
      transition('red => green', animate(1000)),
    ]),
    trigger('fadeInOut', [
      state('visible', style({ opacity: 1, height: '*' })),
      state('hidden', style({ opacity: 0, height: '0px' })),
      transition('visible <=> hidden', [animate('2000ms ease-in-out')]),
    ]),
  ],
})
export class ProductDetailQuickViewComponent implements OnInit, OnDestroy {
  @Input() alreadyExists!: boolean;
  @Input() packageProductsPath : string = 'store/packages';
  @Input() itemProductsPath : string = 'store/items';
  @Input() parentPath : string = 'store';
  inputAnimate = '';
  isMessageClosed = false;
  countExceededMsg = false;
  lessThanMinimumMsg = false;
  images: any[] = [];
  itemCount!: number;
  productItem!: GiftItem;
  productPackage!: GiftPackage;
  isItem!: boolean;
  imageIds!: string;
  productName!: string;
  productDescription!: string;
  currentCart!: ShoppingCart;
  cartId!: number;
  addingCartSuccess = false;
  isOutStock!: boolean;
  reducedPrice: number | undefined;
  timer!: NodeJS.Timeout;
  imageWidth: number = 0;
  isCustomizedGift!: boolean;
  maxItemCount: number = environment.defaultCustomItemMaxCount;
  minItemCount: number = environment.defaultCustomItemMinCount;
  minQuantity: number | null = null;
  maxQuantity: number | null = null;
  message: string = '';
  showMessageBox: boolean = false;
  isCustomizableCake: boolean = false;
  //additional details of each product
  additionalDetails!: string[];

  private unsubscribe = new Subject<void>();
  imagesSlider: OwlOptions = {
    loop: true,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: false,
    dots: false,
    navSpeed: 700,
    navText: ['◄', '►'],
    items: 1,
    nav: false,
    autoplay: true,
    autoWidth: true,
  };

  thumbnailsSlider: OwlOptions = {
    items:5,
    dots:false,
    margin:5,
    autoWidth: true,
    loop: false,
    mouseDrag: true,
    touchDrag: true,
    pullDrag: true,
    navSpeed: 700,
    center: false,
    navText: ['◄', '►'],
    nav: false,
    autoplay: false
  };

  horizontalPosition: MatSnackBarHorizontalPosition = 'center';
  verticalPosition: MatSnackBarVerticalPosition = 'top';
  selectedImage!: number;
  activeSlides!: SlidesOutputData;
  @ViewChild('thumbSlider') thumbSlider!: CarouselComponent;
  previousUrl!: string;

  constructor(
    public activeModal: NgbActiveModal,
    private productDetailViewService: ProductDetailQuickViewService,
    private shoppingCartService: ShoppingCartService,
    private _snackBar: MatSnackBar,
    private spinner: SpinnerService,
    private modalService: NgbModal,
    private router: Router, private route: ActivatedRoute,
    private location: Location,
    private titleService: Title,
    private metaService: Meta,
    private routerEventService: RouterEventService
  ) {}


  ngOnInit(): void {
    this.spinner.requestStarted();
    this.isItem = this.productDetailViewService.isItem;
    this.isOutStock = this.productDetailViewService.isOutStock;
    this.reducedPrice = this.productDetailViewService.reducedPrice;

    if (this.isItem) {
      this.loadGiftItemAndCart();
    } else {
      this.loadGiftPackage();
    }
  }

  /**
   * [User defined method] -> Checks if it is a gift-item and if it is, then it loads the item from 'productItemSubject'
   * to 'item'  and 'shopping cart' to 'cart'...  The item gets a value after 'take(1)' emits a value
   * and it happens when 'productDetailViewService.changeItem()' called.
   * Then 'take(1)' emits a value and forkJoin becomes successfull.
   */
  loadGiftItemAndCart() {
    forkJoin({
      item: this.productDetailViewService.productItemSubject.pipe(take(1)),
      cart: this.shoppingCartService.loadShoppingCart(),
    })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        if (res.item && res.cart) {
          this.productItem = res.item;
          this.currentCart = res.cart;
          this.isCustomizableCake = this.isCustomizable(this.productItem.giftItemOptions[0].features);
          this.minQuantity = this.getMinQuantity(this.productItem.giftItemOptions[0].features);
          this.maxQuantity = this.getMaxQuantity(this.productItem.giftItemOptions[0].features);
          this.productName = res.item.itemName;
          this.productDescription = res.item.itemShortDesc;
          //this.addParam(res.item.itemId);
          this.addParam(res.item.code, res.item.itemName);
          // Replace the page title
          this.titleService.setTitle(this.productName);

          // Replace the meta description
          this.metaService.updateTag({
            name: 'description',
            content: this.productDescription
          });
          const cartItem = res.cart?.cartItems?.filter(
            (cItem) =>
              Number(cItem.product.inventoryId.split('-')[1]) ==
              this.productItem.itemId
          )[0];
          this.itemCount = cartItem ? cartItem.quantity : this.setItemCounttoMin();
          this.message = cartItem ? cartItem.itemMessage ?? "" : "";
          this.showMessageBox = this.message.length > 0;
          if (res.item.itemLongDesc) {
            this.additionalDetails = res.item.itemLongDesc.replace(/^<p>|<\/p>$/g, '').split(',');
          }
          this.getItemImages(res.item);
          this.spinner.requestEnded();
        }
      });
    this.productDetailViewService.changeItem();
  }

  /**
   * [User defined method] -> Checks if it is a gift-package and if it is, then it loads the package from 'productPackageSubject'
   * to 'package'  and 'shopping cart' to 'cart'...  The package gets a value after 'take(1)' emits a value
   * and it happens when 'productDetailViewService.changePackage()' called.
   * Then 'take(1)' emits a value and forkJoin becomes successfull.
   */
  loadGiftPackage() {
    forkJoin({
      package: this.productDetailViewService?.productPackageSubject?.pipe(
        take(1)
      ),
      cart: this.shoppingCartService.loadShoppingCart(),
    })
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((res) => {
        if (res.package && res.cart) {
          this.productPackage = res.package;
          this.currentCart = res.cart;
          this.isCustomizableCake = this.isCustomizable(this.productPackage.giftPackageOptions[0].features);
          this.minQuantity = this.getMinQuantity(this.productPackage.giftPackageOptions[0].features);
          this.maxQuantity = this.getMaxQuantity(this.productPackage.giftPackageOptions[0].features);
          this.productName = res.package.packageName;
          this.productDescription = res.package.shortDescription;
          //this.addParam(res.package.packageId);
          this.addParam(res.package.code, res.package.packageName);
          // Replace the page title
          this.titleService.setTitle(this.productName);

          // Replace the meta description
          this.metaService.updateTag({
            name: 'description',
            content: this.productDescription
          });
          const cartItem = res.cart?.cartItems?.filter(
            (cItem) =>
              Number(cItem.product.inventoryId.split('-')[1]) ==
              this.productPackage.packageId
          )[0];
          this.itemCount = cartItem ? cartItem.quantity : this.setItemCounttoMin();
          this.message = cartItem ? cartItem.itemMessage ?? "" : "";
          this.showMessageBox = this.message.length > 0;
          if (res.package.longDescription) {
            this.additionalDetails = res.package.longDescription.replace(/^<p>|<\/p>$/g, '').split(',');
          }
          this.getPackageImages(res.package);
          this.spinner.requestEnded();
        }
      });
    this.productDetailViewService.changePackage();
  }

  setItemCounttoMin() {
    const defaultMinCount = this.minItemCount ? this.minItemCount : 1;
    this.itemCount = this.minQuantity ? this.minQuantity : defaultMinCount;
    return this.itemCount;
  }

  toggleMessageBox(){
    this.showMessageBox = !this.showMessageBox;
    // If Item already is in the cart, update message
    if(this.currentCart?.cartItems){
      const cartItem = this.isItem ? this.getCartItemByItemId(this.productItem.itemId) : this.getCartItemByItemId(this.productPackage.packageId);
      if(cartItem && cartItem?.itemMessage !== this.message){
        cartItem.itemMessage = this.message;
        this.saveMessage(cartItem);
      }
    }
  }

  saveMessage(cartItem: CartItem){
    var itemMessage: ItemMessage = {
      cartId: this.currentCart.id,
      itemId: cartItem.id,
      itemMessage: cartItem.itemMessage?.trim() ?? null
    }
    this.shoppingCartService.updateCartItemMessage(itemMessage).subscribe({
      next: (result) => {
        if(!result){
          this.addingMsg("An error occurred while updating custom message.", undefined, {
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            panelClass: 'light-error-snackbar'
          }, 3000);
        }
      },
      error: () => {
        this.addingMsg("An error occurred while updating custom message", undefined, {
          horizontalPosition: this.horizontalPosition,
          verticalPosition: this.verticalPosition,
          panelClass: 'light-error-snackbar'
        }, 3000);
      },
      complete: () => {}
    })
  }

  isCustomizable(str: string): boolean {
    // Check if the string contains 'customizable'
    if (str.includes('customizable')) {
        // Extract the part of the string that contains 'customizable'
        const customizableValue = str.match(/customizable:\s*(true|false)/)?.[1];

        if (customizableValue) {
            // Convert the string 'true' or 'false' to a boolean
            return customizableValue === 'true';
        }
    }
    // Return false if 'customizable' is not found or no value is set
    return false;
  }

  getMinQuantity(inputString: string) : number | null  {
    const minQuantityMatch = inputString.match(/minQuantity:(\d+)/);
    return minQuantityMatch ? parseInt(minQuantityMatch[1], 10) : null;
  }

  getMaxQuantity(inputString: string) : number | null  {
    const maxQuantityMatch = inputString.match(/maxQuantity:(\d+)/);
    return maxQuantityMatch ? parseInt(maxQuantityMatch[1], 10) : null;
  }

  @HostListener('window:popstate', ['$event'])
  onPopState(event: any) {
    this.activeModal.dismiss();
    if(this.routerEventService.routes.length >= 2 && this.routerEventService.routes[this.routerEventService.routes.length - 2].includes('/store/')) {
      this.router.navigateByUrl('');
      //window.location.replace(environment.appBaseUrl);
    }
  }

  addParam(productCode: String, productName?: string) {
    this.previousUrl = this.location.path();

    const urlParts = this.previousUrl.split('&');
    let newUrl = '';

    let sanitizedProductName = productName
      ? productName.trim().replace(/\s+/g, '-').toLowerCase()
      : '';

    const parts = this.router.url.split('/');

    if (this.productPackage) {
        newUrl = `${this.packageProductsPath}/${productCode}`;
        this.location.replaceState(newUrl);
    } else if (this.productItem) {
        newUrl = `${this.itemProductsPath}/${productCode}`;
        this.location.replaceState(newUrl);
    }
}

  getItemImages(item: GiftItem) {
    item.cloudFiles.forEach((item: any) => this.images.push(item.cloudFile));
    this.setCarouselWidth();
  }

  getPackageImages(pack: GiftPackage) {
    pack.cloudFiles.forEach((item: any) => this.images.push(item.cloudFile));
    this.setCarouselWidth();
  }

  setCarouselWidth(){
    if(this.images.length <= 2){
      this.imageWidth = 100;
    }
  }

  getAltMsg(img: CloudFile){
    if(img){
      return img.name;
    };

    return 'Product image';
  }

  ngOnDestroy() {
    this.activeModal.dismiss();

    this.location.replaceState(this.previousUrl);

    this.unsubscribe.next();
    this.unsubscribe.complete();
}

  decrementCont() {
    clearTimeout(this.timer);
    if(this.minQuantity){
      this.minItemCount = this.minQuantity;
    }

    if (this.itemCount > 1 && this.itemCount > this.minItemCount) {
      this.itemCount--;
      this.countExceededMsg = false;
    } else {
      this.lessThanMinimumMsg = true;
    }
    this.inputAnimate = 'red';
    setTimeout(() => {
      this.inputAnimate = 'white';
    }, 1200);
    this.timer = setTimeout(() => {
      this.lessThanMinimumMsg = false;
    }, 10000);
  }

  incrementCont() {
    clearTimeout(this.timer);
    if(this.maxQuantity){
      this.maxItemCount = this.maxQuantity;
    }

    if (this.itemCount >= 1 && this.itemCount < this.maxItemCount) {
      this.itemCount++;
      this.lessThanMinimumMsg = false;
    } else {
      this.countExceededMsg = true;
    }
    this.inputAnimate = 'green';
    setTimeout(() => {
      this.inputAnimate = 'white';
    }, 1200);
    this.timer = setTimeout(() => {
      this.countExceededMsg = false;
    }, 10000);
  }

  addToCart() {
    if(environment.underMaintenance) {
      let supportPhone = environment.supportPhone;
      this.addingMsg(`Our online order system is currently under construction. For reservations, please contact WrapMe support at this number: ${supportPhone}`, undefined, {
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        panelClass: 'light-error-snackbar',
      }, 10000);
      return;
    }

    this.isMessageClosed = !this.isMessageClosed;
    this.addingCartSuccess = false;
    const inventoryId = this.isItem
      ? `GiftItem-${this.productItem.itemId}`
      : `GiftPackage-${this.productPackage.packageId}`;

    if (this.isOutStock) {
      this.addingMsg(`${ this.isItem ? this.productItem.itemName : this.productPackage.packageName } is out of stock!`, undefined, {
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        panelClass: 'light-error-snackbar',
      }, 5000);
      return;
    }

    if (this.maxQuantity && this.maxQuantity < this.itemCount) {
      this.addingMsg(`You cannot purchase more than ${ this.maxQuantity } for this item!`, undefined, {
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        panelClass: 'light-error-snackbar',
      }, 5000);
      return;
    }

    if (this.minQuantity && this.minQuantity > this.itemCount) {
      this.addingMsg(`You cannot purchase less than ${ this.minQuantity } for this item!`, undefined, {
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition,
        panelClass: 'light-error-snackbar',
      }, 5000);
      return;
    }

    this.shoppingCartService
      .loadShoppingCart()
      .pipe(
        mergeMap((cart) => {
          this.currentCart = cart;
          this.cartId = cart.id;
          return this.shoppingCartService.noExistingGiftItems(
            this.cartId,
            inventoryId
          ).pipe(
            map(value => {
              return {cart : cart, success : value};
            })
          );
        }),
        mergeMap((response) => {
          if (response.success) {
            return this.shoppingCartService.addCartItemByInventoryIdWithMessage(
              this.cartId,
              inventoryId, {
                itemMessage: this.message ? this.message : null,
                inventoryEntry: {
                  units: this.itemCount ? this.itemCount : 1,
                  unitsOfMeasure: UnitsOfMeasure.qty,
                }
              }
            );
          } else {
            return of(null);
          }
        })
      )
      .subscribe({
        next: (res) => {
          if(res) {
            this.addingCartSuccess = true;
            this.showMessageBox = false;
            this.alreadyExists = true;
            this.lessThanMinimumMsg = false;
            this.countExceededMsg = false;

          } else {
            this.cartItemExistsMsg();
          }
        },
        error : (error : HttpErrorResponse) => {
          this.addingMsg(`Cannot add to cart, ${this.shoppingCartService.mapAddToCartError(error)}`, undefined, {
            horizontalPosition: this.horizontalPosition,
            verticalPosition: this.verticalPosition,
            panelClass: 'light-error-snackbar',
          }, 5000);
        }
      });
  }

  cartItemExistsMsg() {
    const modalRef = this.modalService.open(AddToCartAmountUpdateMessageComponent);
    modalRef.componentInstance.itemCountFromQuickDetailWindow = this.itemCount;
    modalRef.componentInstance.maxQuantity = this.maxQuantity;
    modalRef.componentInstance.minQuantity = this.minQuantity;
    modalRef.componentInstance.itemId = this.isItem ? this.productItem.itemId : this.productPackage.packageId;
  }

  getCartItem(invId: string): CartItem {
    return this.currentCart.cartItems.filter(
      (item) => item.product.inventoryId === invId
    )[0];
  }

  getCartItemByItemId(itemId: number): CartItem {
    return this.currentCart.cartItems?.filter(
      (item) => item.product.inventoryProduct.itemId === itemId
    )[0];
  }

  closeMessage() {
    this.isMessageClosed = false;
    this.countExceededMsg = false;
    this.lessThanMinimumMsg = false;
  }

  addingMsg(message: string, action = '', config?: MatSnackBarConfig, timeout? : number) {
    this._snackBar.open(message, action, config);
    setTimeout(() => {
      this._snackBar.dismiss();
    }, timeout ? timeout : 3500);
  }

  slideChanged(data: SlidesOutputData) {
    this.activeSlides = data;
    this.selectedImage = this.activeSlides.startPosition ?? 0;
    const element = document.getElementsByClassName('product-gallery__carousel-item--active')[0];
    const parent = element ? element.parentElement?.parentElement?.nextElementSibling : null;

    if(!parent?.classList.contains('active')){
      this.thumbSlider?.to(this.selectedImage.toString())
    }
  }

  changeImage(index: number){
    this.selectedImage = index;
  }
}
