import {Inject, Injectable} from '@angular/core';
import {Discount} from "../Models/discount.model";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {GiftPackage} from "../Models/gift-package.model";
import {GiftItem} from "../Models/gift-item.model";

@Injectable({
  providedIn: 'root'
})
export class ProductDiscountService {

  constructor(@Inject('BASE_API_URL') private apiBaseUrl: string, private http: HttpClient) {
  }

  getDiscount(id: number): Observable<Discount> {
    return this.http.get<Discount>(`${this.apiBaseUrl}api/Discounts/${id}`);
  }

  calculateItemFinalPrice(giftItem: GiftItem): number | undefined {
    let giftPackageDiscounts = giftItem.giftItemDiscounts as {
      packageId: number,
      discountId: number,
      discount: Discount
    }[];

    let price = giftItem.price.amount as number;
    if (giftPackageDiscounts.length == 0) {
      return undefined;
    }

    return  giftPackageDiscounts.reduce((finalPrice, product) => {
      return finalPrice - (this.calculateDiscount(price, product.discount));
    }, price);
  }

  calculatePackageFinalPrice(productPackage: GiftPackage): number | undefined {
    let giftPackageDiscounts = productPackage.giftPackageDiscounts as {
      packageId: number,
      discountId: number,
      discount: Discount
    }[];

    let price = productPackage.price.amount as number;
    if (giftPackageDiscounts.length == 0) {
      return undefined;
    }

    return  giftPackageDiscounts.reduce((finalPrice, product) => {
      return finalPrice - (this.calculateDiscount(price, product.discount));
    }, price);
  }

  calculateDiscount(targetAmount: number, discount: Discount): number {
    const discountType = discount.discountType as string | undefined;
    if (!discountType) {
      return 0.0;
    }

    switch (discountType) {
      case 'FixedAmount':
      case 'CategoryAmount':
        return discount.discountValue?.amount ?? 0;
      case 'Percentage':
      case 'CategoryPercentage':
        return (targetAmount * (discount.discountPercentage ?? 0)) / 100;
      default:
        return 0.0;
    }
  }
}
