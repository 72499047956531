import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'xeyn-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  currentYear: number = new Date().getFullYear();
  logoLight = `${environment.cdnUrl}brandings/WrapMe-logo-light-nav.webp`;
  constructor(private router: Router, private modalService: NgbModal) {
  }
  ngOnInit(): void {
  }
  toCustomGifts() {
    this.dismissModals();
    this.router.navigate(['/store/items']);
  }

  toStore() {
    this.dismissModals();
    this.router.navigate(['/store/packages']);
  }
  private dismissModals() {
    if (this.modalService.hasOpenModals()) {
      this.modalService.dismissAll();
    }
  }

}
