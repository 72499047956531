import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';
import {GiftItem} from 'src/app/Models/gift-item.model';
import {GiftPackage} from 'src/app/Models/gift-package.model';
import {SupSvrDealProduct} from "../../Models/super-saver-product";
import {
  ProductDetailQuickViewComponent
} from "../../shared/product-detail-quick-view/product-detail-quick-view.component";
import {SpinnerService} from "@xyg/spinner";
import {GiftyAppService} from "../gifty-app.service";
import {ProductDiscountService} from "../../shared/product-discount.service";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";

@Injectable({
  providedIn: 'root'
})
export class ProductDetailQuickViewService {

  productItem!: GiftItem;
  productPackage!: GiftPackage;
  isCustomizedGift!: boolean;
  isItem!: boolean;
  isOutStock!: boolean;
  reducedPrice: number | undefined;

  public productItemSubject = new Subject<GiftItem>();
  public productPackageSubject = new Subject<GiftPackage>();

  constructor(private spinnerService: SpinnerService,
              private giftyService: GiftyAppService,
              private productDiscountService: ProductDiscountService,
              private modalService: NgbModal) {
  }

  changeItem() {
    this.productItemSubject.next(this.productItem);
  }

  changePackage() {
    this.productPackageSubject.next(this.productPackage);
  }

  quickViewProduct(product: SupSvrDealProduct, isCustomItem: boolean) {
    if (isCustomItem) {
      this.spinnerService.requestStarted();
      this.giftyService.getGiftItem(product.id)
        .subscribe(
          {
            next: value => this.quickViewProductItem(value),
            error: err => this.spinnerService.requestEnded(),
            complete: () => this.spinnerService.requestEnded()
          }
        )
    } else {
      this.spinnerService.requestStarted();
      this.giftyService.getGiftPackage(product.id)
        .subscribe(
          {
            next: value => this.quickViewProductPackage(value),
            error: err => this.spinnerService.requestEnded(),
            complete: () => this.spinnerService.requestEnded()
          }
        )
    }
  }

  quickViewProductItemById(id: number, postOpenAction : (modelRef : NgbModalRef) => void) {
    this.spinnerService.requestStarted();
    this.giftyService.getGiftItem(id).subscribe({
      next: (productItem) => {
        let ngbModalRef = this.quickViewProductItem(productItem);
        postOpenAction(ngbModalRef);
      },
      error: (_) => {
        this.spinnerService.requestEnded();
      },
      complete: () => {
        this.spinnerService.requestEnded();
      },
    });
  }

  quickViewProductPackageById(id: number, postOpenAction : (modelRef : NgbModalRef) => void) {
    this.spinnerService.requestStarted();
    this.giftyService.getGiftPackage(id).subscribe({
      next: (productPackage) => {
        let ngbModalRef = this.quickViewProductPackage(productPackage);
        postOpenAction(ngbModalRef);
      },
      error: (_) => {
        this.spinnerService.requestEnded();
      },
      complete: () => {
        this.spinnerService.requestEnded();
      },
    });
  }

  quickViewProductPackage(productPackage: GiftPackage) : NgbModalRef  {
    this.productPackage = productPackage;
    this.isItem = false;
    this.isCustomizedGift = false;
    this.isOutStock = this.isPackageOutOfStock(productPackage);
    this.reducedPrice = this.productDiscountService.calculatePackageFinalPrice(productPackage);
    return this.modalService.open(ProductDetailQuickViewComponent, {centered: true, size: 'xl'});
  }

  quickViewProductItem(productItem: GiftItem) : NgbModalRef {
    this.productItem = productItem;
    this.isItem = true;
    this.isCustomizedGift = true;
    this.isOutStock = this.isItemOutOfStock(productItem);
    this.reducedPrice = this.productDiscountService.calculateItemFinalPrice(productItem);
    return this.modalService.open(ProductDetailQuickViewComponent, {
      centered: true,
      size: 'xl',
      fullscreen: 'lg',
      scrollable: true,
    });
  }

  public isItemOutOfStock(productItem: GiftItem): boolean {
    return (productItem.giftItemStock.currentStockSize <= 0 &&
        productItem.giftItemStock.status.toLowerCase() != 'free stock') ||
      productItem.giftItemStock.status.toLowerCase() == 'out of stock';
  }

  public isPackageOutOfStock(productItem: GiftPackage): boolean {
    return (productItem.giftPackageStock.currentStockSize <= 0 &&
        productItem.giftPackageStock.status.toLowerCase() != 'free stock') ||
      productItem.giftPackageStock.status.toLowerCase() == 'out of stock';
  }
}
